var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("feather-icon", {
        attrs: {
          title: "View Details",
          icon: "EyeIcon",
          svgClasses: "h-5 w-5 mr-2 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.displayRecord },
      }),
      _vm.userHasPermission("apps_customers_edit,apps_customers_add")
        ? _c("feather-icon", {
            attrs: {
              title: "Edit",
              icon: "Edit3Icon",
              svgClasses: "h-5 w-5 mr-2 hover:text-primary cursor-pointer",
            },
            on: { click: _vm.editRecord },
          })
        : _vm._e(),
      _c("feather-icon", {
        attrs: {
          title: "View Usage",
          icon: "BarChartIcon",
          svgClasses: "h-5 w-5 hover:text-primary cursor-pointer",
        },
        on: { click: _vm.displayCreditUsage },
      }),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Delete Customer",
            "accept-text": "Delete",
            active: _vm.showDeleteDialog,
          },
          on: {
            cancel: _vm.closeDeleteDialog,
            close: _vm.closeDeleteDialog,
            accept: _vm.deleteCustomer,
            "update:active": function ($event) {
              _vm.showDeleteDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row mt-2 mb-4" }, [
            _c("div", { staticClass: "vx-col" }, [
              _c("span", [
                _vm._v(
                  "Are you sure you want to delete customer?\n            "
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }